import Header from './Header';
import MB from '../img/MB.webp'
import MB_growing from '../img/MB_growing.webp'

function Health() {

    return(
        <div className="home-container">
            <div className="app">
                <Header />
            </div>
            <h1>
                Health & Wellness
            </h1>
            <div className='hw_img'>
                <img src={MB} alt="" className='hw_images'/>
                <img src={MB_growing} alt="" className='hw_images'/>
            </div>
            <div className='mb_content'>
                <h2>
                Our Miracle Box continues to grow...
                </h2>
                <ul className='mb_list'>
                    <li>
                        Climbed our 2nd Mountain late Spring 2024, and planning 3rd!
                    </li>
                    <li>
                        Climbing stairs daily easily!
                    </li>
                    <li>
                        Climbing in/out of vehicles easily!
                    </li>
                    <li>
                        Getting on the floor w/Grands w/o fear!
                    </li>
                    <li>
                        Knee, Neck, Shoulder, Spine feeling Ahmazing
                    </li>
                    <li>
                        Sleep is deeper and more refreshing!
                    </li>
                    <li>
                        Nerve and Shocky issues greatly reduced
                    </li>
                    <li>
                        Mobility is Ahmazing
                    </li>
                    <li>
                        Discomfort scale is 0-1 from 6-8 99% daily
                    </li>
                    <li>
                        Overall Whole Body Wellness
                    </li>
                    <li>
                        Mental Clarity & Focus
                    </li>
                    <li>
                        Friends Lives Changing
                    </li>
                </ul>
                <p>
                Yall, to be able to plan to do things I haven't been able to do for since arriving in This beautiful 
                valley is so freeing! Having the ability to "choose" healthier activities that I have always enjoyed 
                but haven't been physically able to do in the last several years IS such an incredible blessing and 
                we give God the glory and praise for His goodness, and for friends willing to continue to reach out 
                and share!
                </p>
            </div>
            <div>
                <a href="https://plexusworldwide.com/melodyshipley" className="buttons">Plexus</a>
            </div>
            <div>
                <a href="https://www.lifewave.com/2106148" className="buttons">Lifewave</a>
            </div>
        </div>
    )
}

export default Health