import React, { useState, useEffect } from 'react';
import logo from "../img/m&m.JPG";
import SlidingMenu from '../components/slidingmenu';




const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 871);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 871);
    };

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='d-flex'>
    <div className={`header ${isScrolled ? 'hidden' : ''}`}>
        <div>
          <img src={logo} alt="" className='logo img-fluid' />
        </div>
        <div className={`hamburger-menu ${isSmallScreen ? 'visible' : ''}`}>
        {/* Render the hamburger button */}
        <button className={`hamburger ${isScrolled ? 'hidden' : ''}`} aria-label="Menu" onClick={toggleMenu}>&#9776;</button>
        {/* Render the sliding menu */}
        
      </div>
        {!isSmallScreen && (
          <div className={`buttons-container ${isScrolled ? 'hidden' : ''}`}>
            <a href="/" className="buttons">Home</a>
            <a href="/realestate" className="buttons">Realestate</a>
            <a href="/health" className="buttons">Health & Wellness</a>
            <a href="/ministry" className="buttons">Ministries</a>
          </div>
        )}
      </div>
          <div>
          <SlidingMenu isOpen={isMenuOpen} />
          </div>

    </div>
  );
};

export default Header;