import Header from './Header';
import realty_img from '../img/realty_img.jpg'

function Realestate() {

  return(
      <div className="home-container">
        <div className="app">
          <Header />
        </div>
        <div className="contact-banner">
          <img src={realty_img} alt="" className='contact-img-banner' />
        </div>
          <div>
            <a href="https://melodyshipley.kw.com/" className="buttons">Keller Williams</a>
          </div>
          <div>
              <a href="https://www.facebook.com/profile.php/?id=100064178798640" className="buttons">Facebook</a>
          </div>
      </div>
  )
}

export default Realestate