import Header from './Header';

function Ministries() {

  return(
      <div className="home-container">
        <div className="app">
          <Header />
        </div>
          <div>
            <a href="https://www.facebook.com/profile.php/?id=100064851334863" className="buttons">Facebook</a>
          </div>
      </div>
  )
}

export default Ministries