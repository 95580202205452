import logo from "../img/main_page_img.jpg"
import Header from './Header';


function Home() {

    return (
        <div className="home-container">
            <div className="app">
                <Header />
            </div>
            <div className="contact-banner">
                <img src={logo} alt="" className='contact-img-banner' />
            </div>
            <div>
            </div>
        </div>
        
    )
}

export default Home