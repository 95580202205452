import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import Health from './components/Health';
import Realestate from './components/realestate';
import Ministries from './components/ministries';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/health" element={<Health />} />
          <Route path="/realestate" element={<Realestate />} />
          <Route path="/ministry" element={<Ministries />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
